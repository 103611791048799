// extracted by mini-css-extract-plugin
export var block = "style-module--block--7d1d2";
export var blockContainer = "style-module--blockContainer--317a5";
export var button = "style-module--button--2cf00";
export var buttonBlog = "style-module--buttonBlog--74831";
export var buttonClose = "style-module--buttonClose--af33d";
export var footerButtons = "style-module--footerButtons--a1f21";
export var form = "style-module--form--d8151";
export var h1 = "style-module--h1--d8ef7";
export var h2 = "style-module--h2--b043f";
export var h3 = "style-module--h3--70e25";
export var heroContainer = "style-module--heroContainer--0bbda";
export var howItWorks = "style-module--howItWorks--9c955";
export var image = "style-module--image--b333d";
export var input = "style-module--input--276cc";
export var inputGroup = "style-module--inputGroup--ccc83";
export var inputRow = "style-module--inputRow--218d3";
export var inputWrapper = "style-module--inputWrapper--4a4f4";
export var label = "style-module--label--94be6";
export var modal = "style-module--modal--fc6c2";
export var note = "style-module--note--36723";
export var subline = "style-module--subline--af2ee";
export var textBlock = "style-module--textBlock--36bed";
export var textBlockContainer = "style-module--textBlockContainer--0b90c";
export var title = "style-module--title--82608";
export var whatMakesUsDifferent = "style-module--whatMakesUsDifferent--f8b19";