import AddressAutofill from '../../AddressAutofill'
import Modal from '../../Modal'
import Template from '../../Template'
import HomepageImage1 from './1.png'
import HomepageImage2 from './2.png'
import HomepageImage3 from './3.png'
import * as style from './style.module.scss'
import { navigate } from 'gatsby'
import Link from 'gatsby-link'
import React from 'react'
import { connectSearchBox } from 'react-instantsearch-dom'
import { useDialogState, DialogDisclosure } from 'reakit/Dialog'
import {
  unstable_useFormState as useFormState,
  unstable_Form as Form,
  unstable_FormLabel as FormLabel,
  unstable_FormInput as FormInput,
} from 'reakit/Form'

const Doggos = (props) => {
  const form = useFormState({})
  const locationRef = React.useRef()

  function SignUpModal() {
    const signUp = useDialogState({ baseId: 'signUp' })

    return (
      <>
        <DialogDisclosure className={style.buttonSubmit} {...signUp}>
          Find a dog sitter
        </DialogDisclosure>
        <Modal {...signUp}>
          <div className={style.modal}>
            <div>
              <div className={style.title}>
                <h2>We'll be live soon!</h2>
                <button
                  className={style.buttonClose}
                  onClick={() => signUp.hide()}
                >
                  Close
                </button>
              </div>
              <p>Sorry, but we're not live yet 😭</p>
              <p>
                Interested in finding a local dog sitter? Add your email to the
                waitlist and we'll let you know as soon as we launch.
              </p>
              <form
                action='https://www.getdrip.com/forms/743233303/submissions'
                method='post'
                data-drip-embedded-form='743233303'
                id='drip-ef-743233303'
              >
                <div>
                  <label htmlFor={'drip-email'}> Email address</label>
                  <input type='email' id='drip-email' name='fields[email]' />
                </div>
                <div>
                  <button
                    type='submit'
                    value='Sign Up'
                    data-drip-attribute='sign-up-button'
                  >
                    Sign up for the waitlist
                  </button>
                </div>
              </form>
            </div>
            <p className={style.note}>
              By signing up, you agree to receive emails from us. We’ll only
              send you emails related to your account, and we{' '}
              <strong>never</strong> sell personal data.
            </p>
          </div>
        </Modal>
      </>
    )
  }

  return (
    <Template>
      <div className={style.heroContainer}>
        <h1 className={style.h1}>
          Book a safe and caring dog sitter in your area
        </h1>
        <h3 className={style.subline}>
          Need someone to watch your dog? <br /> Find a sitter on doggos.com
        </h3>
      </div>

      {/* this should be a 'form' but i turned it off for launch so the drip form submits */}
      <div
        {...form}
        noValidate={false}
        className={style.form}
        // onSubmit={(event) => {
        //   event.preventDefault()
        //   const data = new FormData(event.target)
        //   props.refine(data.get('query'))
        //   navigate(`/search/?l=${data.get('l')}&query=${data.get('query')}`)
        // }}
      >
        <div className={style.inputRow}>
          <div className={style.inputGroup}>
            <FormLabel className={style.label}>
              What area should we search?{' '}
            </FormLabel>

            <div className={style.inputWrapper}>
              <AddressAutofill
                {...form}
                placeholder='Search by zip code, city, or state'
                name='city'
                placeSelect={(place) => {
                  locationRef.current.value = `${place.properties.lat},${place.properties.lon}`
                }}
              />
              <input ref={locationRef} type='hidden' name='l' />
            </div>
          </div>
          <div className={style.inputGroup}>
            <FormLabel className={style.label}>
              What dates do you need a dog sitter?
            </FormLabel>
            <div className={style.inputRow}>
              <input className={style.input} type='date' />
              <input className={style.input} type='date' />
            </div>
          </div>
        </div>
        <div className={style.inputRow}>
          {/* <button className={style.button} type='submit'>
            Browse dog sitters
          </button> */}
          <div className={style.button}>
            <SignUpModal />
          </div>
        </div>
      </div>
      <div className={style.howItWorks}>
        <h2 className={style.h2}>How it works</h2>
        <div className={style.blockContainer}>
          <div className={style.block}>
            <div className={style.image}>
              <img src={HomepageImage1} height='220' />
            </div>
            <h3 className={style.h3}>
              Find a sitter you & <br />
              your dog love
            </h3>
            <p>
              Browse sitters in your area, with filters for home type, exercise,
              and more
            </p>
          </div>
          <div className={style.block}>
            <div className={style.image}>
              <img src={HomepageImage2} />
            </div>
            <h3 className={style.h3}>
              The lowest fees
              <br /> in the industry
            </h3>
            <p>
              We have a simple 10% fee. Nothing else. Optional insurance
              available for all stays
            </p>
          </div>
          <div className={style.block}>
            <div className={style.image}>
              <img src={HomepageImage3} />
            </div>
            <h3 className={style.h3}>
              Get updates on <br /> your dogs stay
            </h3>
            <p>
              Communicate with your sitter or easily contact support with our
              amazing real-time chat
            </p>
          </div>
        </div>
      </div>
      <div className={style.whatMakesUsDifferent}>
        <h2 className={style.h2}>What makes us different</h2>
        <div className={style.textBlockContainer}>
          <div className={style.textBlock}>
            <h3 className={style.h3}>
              The independent alternative to Rover & Wag
            </h3>
            <p>
              Doggos.com is run by a small, independent, team of dog lovers...
              not a big{' '}
              <a
                href='https://www.blackstone.com/news/press/rover-agrees-to-be-acquired-by-blackstone-in-2-3-billion-transaction/'
                target='_blank'
                rel='noreferrer noopener'
              >
                corpo asset manager
              </a>
              .
            </p>
          </div>
          <div className={style.textBlock}>
            <h3 className={style.h3}>
              Comprehensive background checks for sitters
            </h3>
            <p>
              <strong>Our background checks go above and beyond.</strong> We
              search national, state, <i>and county</i> records before letting
              any new sitters onto our platform.
            </p>
          </div>
          <div className={style.textBlock}>
            <h3 className={style.h3}>The lowest fees in the industry</h3>
            <p>
              Doggos.com is completely FREE to use for sitters, and our
              transaction fees are the lowest of any platform.
            </p>
          </div>
          <div className={style.textBlock}>
            <h3 className={style.h3}>
              <i>Optional</i> insurance
            </h3>
            <p>
              Instead of making it a requirement to pay for insurance... we made
              it optional. You decide if, and when, your pet needs extra
              coverage.
            </p>
          </div>
        </div>

        <div className={style.footerButtons}>
          {/* <Link className={style.buttonBlog} to='/doggos-vs-rover-vs-wag'>
            Learn more about us
          </Link> */}
          <div className={style.button}>
            <SignUpModal />
          </div>
        </div>
      </div>
    </Template>
  )
}

export default connectSearchBox(Doggos)
